import React from 'react';

import { Select as Selector, SelectProps } from '@material-ui/core';

import { Error } from '../textfield/styles';

interface Props extends Omit<SelectProps, 'error'> {
  error?: string;
}

const Select: React.FC<Props> = ({ error, ...props }) => {
  return (
    <>
      <Selector {...props} />
      {error && (
        <Error>{error}</Error>
      )}
    </>
  );
}

export default Select;