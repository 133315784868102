/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Container, FormContainer } from './styles';

import TextField from '../../molecules/textfield';

import PageTitle from '../../atoms/page-title';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useHistory } from 'react-router-dom';
import ChangePasswordModal from '../../molecules/change-password-dialog';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { setUser } from '../../store/modules/auth/actions';

const ProfilePage: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const history = useHistory() as any;
  const dispatch = useDispatch();

  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

  const formik = useFormik({
		initialValues: {
      email: '',
      nome: '',
    },
		validationSchema: Yup.object().shape({
      email: Yup.string().required('Campo obrigatório!').email('Email inválido!'),
      nome: Yup.string().required('Campo obrigatório!'),
    }),
    onSubmit: async (values) => {
      try {
        await api.put(`usuarios/${user.id}`, values);
        dispatch(setUser(values))
        toast.success('Perfil alterado com sucesso!');
        history.goBack();
      } catch (error) {
        const message = error.response?.data?.message || 'Erro ao alterar perfil!';
        toast.error(message);
      }
    }
	});

  function formikProps(fieldName: 'email' | 'nome') {
    const error = (formik.touched[fieldName] ? formik.errors[fieldName] : undefined) as string;

    return {
      value: formik.values[fieldName],
      error
    };
  }

  function handleChange(event: any) {
    const value = event?.target.value;
    const field = event?.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  function handleOpenModal() {
    setShowChangePasswordDialog(true);
  }

  function handleCloseModal() {
    setShowChangePasswordDialog(false);
  }

  useEffect(() => {
    formik.setValues({
      email: user.email,
      nome: user.nome
    })
  }, [user])

  return (
    <>
      <Container>
        <PageTitle label="Perfil" suffix={() => <Button onClick={handleOpenModal} variant="text">Alterar senha</Button>}/>
        <FormContainer>
          <ChangePasswordModal open={showChangePasswordDialog} onClose={handleCloseModal} />
          <Grid container spacing={3} justify="flex-end">
           <Grid item xs={12}>
              <TextField 
                name="nome" 
                label="Nome" 
                fullWidth 
                {...formikProps('nome')}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="email" 
                label="Email" 
                fullWidth 
                {...formikProps('email')}
                onChange={handleChange} 
              />
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => formik.handleSubmit()}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      </Container>
    </>
  );
}

export default ProfilePage;