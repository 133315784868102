import React from 'react';

import { Text, Header } from './styles';
import { Divider } from '@material-ui/core';

interface PageTitleProps {
  label: string;
  suffix?: any;
}

const PageTitle: React.FC<PageTitleProps> = ({label, suffix: Suffix}) => {
  return (
    <>
      <Header>
        <Text variant="h1">
          {label}
        </Text>
        {Suffix && <Suffix />}
      </Header>
      <Divider style={{marginBottom: 20}} />
    </>
  );
}

export default PageTitle;