/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid, Button, MenuItem, InputLabel, FormControlLabel, Switch } from "@material-ui/core";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Container,
  FormContainer,
  Image,
  ImageContainer,
  ImageEditButton,
} from "./styles";

import CropDialog from "../../../molecules/crop-dialog";
import TextField from "../../../molecules/textfield";
import Select from "../../../molecules/select";

import { toast } from "react-toastify";
import PageTitle from "../../../atoms/page-title";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import { format } from "date-fns";

const CouponRegisterPage: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [imageBlob, setImageBlob] = useState();
  const [imagePreview, setImagePreview] = useState();

  const history = useHistory() as any;
  const params = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: {
      descricao: "",
      codigo_promocional: "",
      data_validade: "",
      quantidade_disponivel: "",
      tipo: "",
      desconto: "",
      regulamento: "",
      status: true
    },
    validationSchema: Yup.object().shape({
      descricao: Yup.string().required("Campo obrigatório!"),
      codigo_promocional: Yup.string().required("Campo obrigatório!"),
      data_validade: Yup.date().required("Campo obrigatório!"),
      quantidade_disponivel: Yup.number().required("Campo obrigatório!"),
      tipo: Yup.string().required("Campo obrigatório!"),
      desconto: Yup.number().required("Campo obrigatório!"),
      regulamento: Yup.string().nullable(),
      status: Yup.boolean().required('Campo obrigatório!'),
    }),
    onSubmit: async (values) => {
      // setLoading(true);

      try {
        const formattedValues = {
          ...values,
          imagem_base64: imageBlob,
        };
        if (params.id) {
          await api.put(`/cupons/${params.id}`, formattedValues);
        } else {
          await api.post("/cupons", formattedValues);
        }
        toast.success("Cupom salvo com sucesso!");
        history.goBack();
      } catch (error) {
        toast.error("Erro ao salvar!");
      }
      // setLoading(false);
    },
  });

  function handleDialogClose() {
    setShowDialog(false);
  }

  function handleDialogSubmit(blob: any) {
    if (blob) setImageBlob(blob);
    handleDialogClose();
  }

  function formikProps(
    fieldName:
      | "descricao"
      | "codigo_promocional"
      | "data_validade"
      | "quantidade_disponivel"
      | "tipo"
      | "desconto"
      | "regulamento"
      | "status"
  ) {
    const error = (formik.touched[fieldName]
      ? formik.errors[fieldName]
      : undefined) as string;

    return {
      value: formik.values[fieldName],
      error,
    };
  }

  function handleChange(event: any) {
    const value = event?.target.value;
    const field = event?.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  function handleSwitchChange(event: any) {
    const value = event.target.checked;
    const field = event.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }

  async function setInitialValues() {
    try {
      if (!params.id) return;

      const { data } = await api.get(`cupons/${params.id}`);
      setImageBlob(data.imagem_base64);
      setImagePreview(data.imagem_base64);
      formik.setValues({
        ...data,
        data_validade: format(
          new Date(data.data_validade),
          "yyyy-MM-dd'T'hh:mm"
        ),
      });
    } catch (error) {}
  }

  useEffect(() => {
    setInitialValues();
  }, []);

  return (
    <>
      <CropDialog
        onClose={handleDialogClose}
        open={showDialog}
        onSubmit={handleDialogSubmit}
        imageProps={{
          imagePreview,
          setImagePreview,
          imageBlob,
          setImageBlob,
        }}
      />
      <Container>
        <PageTitle label="Cupom" />
        <FormContainer>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={12}>
              <ImageContainer>
                <Image src={imageBlob as any} crossOrigin="anonymous" />
                <ImageEditButton
                  onClick={() => {
                    setShowDialog(true);
                  }}
                >
                  Alterar imagem
                </ImageEditButton>
              </ImageContainer>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="descricao"
                label="Descrição"
                fullWidth
                {...formikProps("descricao")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="codigo_promocional"
                label="Código promocional"
                disabled={Boolean(params.id)}
                fullWidth
                {...formikProps("codigo_promocional")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="quantidade_disponivel"
                label="Quantidade disponível"
                inputMode="decimal"
                type="number"
                disabled={Boolean(params.id)}
                fullWidth
                {...formikProps("quantidade_disponivel")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                style={{
                  fontSize: 12,
                }}
              >
                Tipo de desconto
              </InputLabel>
              <Select
                name="tipo"
                fullWidth
                {...formikProps("tipo")}
                onChange={handleChange}
              >
                <MenuItem value="PER">Porcentagem</MenuItem>
                <MenuItem value="VAL">Valor</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="desconto"
                label="Valor de desconto"
                type="number"
                fullWidth
                {...formikProps("desconto")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Validade*"
                name="data_validade"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                {...formikProps("data_validade")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="regulamento"
                label="Regulamento"
                fullWidth
                {...formikProps("regulamento")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={
                <Switch 
                  name="status" 
                  {...formikProps('status')} 
                  onChange={handleSwitchChange}
                  checked={formik.values.status}
                />
              } 
              label="Status" />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => history.push("/listagem/cupons")}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => formik.handleSubmit()}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      </Container>
    </>
  );
};

export default CouponRegisterPage;
