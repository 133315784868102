import React, { useState } from 'react';
import { List, ListItem, ListItemText, Collapse, Divider } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { Container, Drawer, ProfileContainer, UserName } from './styles';
import CouponCheckout from '../coupon-checkout';
import { signOut } from '../../store/modules/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

const Layout: React.FC = ({ children }) => {
  const [couponCheckoutOpen, setCouponCheckoutOpen] = useState(false);

  const [registerCollapse, setRegisterCollapse] = useState(false);
  const [routineCollapse, setRoutineCollapse] = useState(false);
  const [reportCollapse, setReportCollapse] = useState(false);

  const { nome } = useSelector((state: RootState) => state.auth.user);


  const dispatch = useDispatch();

  return (
    <>
      <CouponCheckout open={couponCheckoutOpen} onClose={() => setCouponCheckoutOpen(false)} />
      <Drawer variant="permanent">
        <ProfileContainer to="/perfil">
          <UserName variant="h6">{nome}</UserName>
        </ProfileContainer>
        <Divider style={{
          height: 2
        }} />
        <List>
          <Link to="/home">
            <ListItem button>
              <ListItemText primary="Início"/>
            </ListItem>
          </Link>
          <ListItem button onClick={() => setRegisterCollapse(prev => !prev)}>
            <ListItemText>Cadastros</ListItemText>
            {registerCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={registerCollapse} timeout="auto">
            <List component="div" disablePadding>
              <Link to="/listagem/cupons">
                <ListItem button>
                  <ListItemText primary="Cupons"/>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button onClick={() => setRoutineCollapse(prev => !prev)}>
            <ListItemText>Rotinas</ListItemText>
            {routineCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={routineCollapse} timeout="auto">
            <List component="div" disablePadding>
              <ListItem button onClick={() => setCouponCheckoutOpen(true)}>
                <ListItemText primary="Baixa em cupom"/>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => setReportCollapse(prev => !prev)}>
            <ListItemText>Relatórios</ListItemText>
            {reportCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={reportCollapse} timeout="auto">
            <List component="div" disablePadding>
              <Link to="/relatorio/baixa_cupons">
                <ListItem button>
                  <ListItemText primary="Baixa de Cupons"/>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button onClick={() => dispatch(signOut())}>
            <ListItemText primary="Sair"/>
          </ListItem>
        </List>
      </Drawer>
      <Container>
        {children}
      </Container>
    </>
  );
}

export default Layout;