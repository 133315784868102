import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <Container>
      <CircularProgress size={16} style={{
        color: 'white'
      }} />
    </Container>
  );
}

export default Loading;