/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Switch, Redirect } from 'react-router-dom';
import RouteWrapper from '../atoms/route';
import LoginPage from '../pages/login';
import HomePage from '../pages/home';
import ProfilePage from '../pages/profile';
import CouponRegisterPage from '../pages/coupon/register';
import CouponListPage from '../pages/coupon/list';
import CheckoutCouponListPage from '../pages/checkoutCoupon/list';
import { useDispatch } from 'react-redux';
import { setToken } from '../store/modules/auth/actions';

const Routes: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setToken())
  }, [])

  return (
    <Switch>
      <RouteWrapper path="/login" component={LoginPage} />
      <RouteWrapper path="/home" component={HomePage} isPrivate />
      <RouteWrapper path="/cadastro/cupom/:id?" component={CouponRegisterPage} isPrivate />
      <RouteWrapper path="/listagem/cupons" component={CouponListPage} isPrivate />
      <RouteWrapper path="/relatorio/baixa_cupons" component={CheckoutCouponListPage} isPrivate />
      <RouteWrapper path="/perfil" component={ProfilePage} isPrivate />
      <RouteWrapper path="/" component={() => <Redirect to="/home" />} />
    </Switch>
  );
}

export default Routes;