import React from "react";
import MaterialTable from "material-table";

const MyTable = ({ title, tableRef, actions, columns, getData }: any) => {
  return (
    <>
      <MaterialTable
        title={title}
        tableRef={tableRef}
        data={getData as any}
        options={{
          paginationType: "normal",
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 30],
          showEmptyDataSourceMessage: true,
          exportButton: true,
          exportDelimiter: ";",
          exportAllData: true,
          search: false,
          filtering: true,
          columnsButton: true,
          toolbar: true,
          toolbarButtonAlignment: "right",
          debounceInterval: 320,
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          body: {
            emptyDataSourceMessage: "Nenhum resultado encontrado.",
            editRow: {
              deleteText: "Deseja realmente excluir este item?",
            },
            filterRow: {
              filterTooltip: "Filtro",
            },
          },
          toolbar: {
            exportTitle: "Exportar",
            showColumnsTitle: "Exibir colunas",
            addRemoveColumns: "Adicionar ou remover colunas",
          },
          pagination: {
            labelRowsSelect: "Linhas",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Ultima página",
            firstTooltip: "Primeira página",
            labelDisplayedRows: "{from}-{to} de {count}",
          },
        }}
        columns={columns.map((column: any) => ({
          ...column,
          hiddenByColumnsButton: column.hidden === true,
        }))}
        actions={actions}
      />
    </>
  );
};

export default MyTable;
