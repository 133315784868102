/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '../../molecules/textfield';
import { Autocomplete } from '@material-ui/lab';
import api from '../../services/api';
import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../store';

interface Props {
  open: boolean;
  onClose(): void;
}

const CouponCheckout: React.FC<Props> = ({ open, onClose }) => {
  const [coupons, setCoupons] = useState([]);

  const formik = useFormik({
    initialValues: {
      cupom: '' as any,
      documento: ''
    },
    validationSchema: Yup.object().shape({
      cupom: Yup.string().required('Campo obrigatório!'),
      documento: Yup.string().required('Campo obrigatório!').matches(/^\d{3}?.\d{3}?.\d{3}?-\d{2}$/g, 'Documento inválido!'),
    }),
    onSubmit: async (values) => {
      try {
        await api.post('cupom/baixa', {
          id_cupom: values.cupom.value,
          documento: values.documento
        })
        handleClose()
      } catch (error) {
        const message = error.response?.data?.message || 'Erro ao dar baixa em cupom!';
        toast.error(message)
      }
    }
  });

  function handleClose() {
    onClose();
    formik.setValues({
      cupom: '',
      documento: ''
    });
  }

  useEffect(() => {
    if(open) {
      requestCoupons();
    }
    formik.setErrors({})
  }, [open])

  function formikProps(fieldName: 'cupom' | 'documento') {
    const error = (formik.touched[fieldName] ? formik.errors[fieldName] : undefined) as string;

    return {
      value: formik.values[fieldName],
      error
    };
  }

  async function requestCoupons() {
    try {
      const { data } = await api.get(`cupons?status=1`);
      setCoupons(data.map((cupom: any) => ({
        value: cupom.id,
        label: `${cupom.codigo_promocional} / ${cupom.descricao}`
      })));
    } catch (error) {
      const message = error.response?.data?.message || 'Erro ao solicitar cupons!';
      toast.error(message)
    }
  }

  // function handleChange(event: any) {
  //   const value = event?.target.value;
  //   const field = event?.target.name;

  //   formik.setFieldTouched(field);
  //   formik.setFieldValue(field, value);
  // }

  function handleAutocompleteChange(field: string, option: any) {
    const value = option;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  } 

  const handleDocumentChange = (event: any) => {
    const value = event.currentTarget.value;
    let maskedValue = value.replace(/\D/g, '');
    maskedValue = maskedValue.replace(/(\d{3})(\d)/, '$1.$2');
    maskedValue = maskedValue.replace(/(\d{3})(\d)/, '$1.$2');
    maskedValue = maskedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    formik.setFieldValue('documento', maskedValue);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Baixa de cupom</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
           <Autocomplete
              options={coupons}
              getOptionLabel={(option: any) => option && option.label}
              noOptionsText="Sem opções"
              value={formik.values.cupom}
              onChange={(event: any, option: any) => handleAutocompleteChange('cupom', option)}
              renderInput={params => (
                <TextField 
                  {...params}
                  name="cupom"
                  label="Cupom"
                  fullWidth
                  {...formikProps('cupom')} 
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="documento" 
              label="CPF" 
              fullWidth 
              {...formikProps('documento')} 
              onChange={handleDocumentChange} 
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button variant="contained" onClick={() => formik.handleSubmit()}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CouponCheckout;