import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Text = styled(Typography)`
  font-size: 28px !important;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 5px;
`;