import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import TextField from '../textfield';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
// import { useHistory } from 'react-router-dom';

interface ChangePasswordModalProps {
  open: boolean;
  onClose(): void;
}

// import { Container } from './styles';

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ onClose, open }) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const formik = useFormik({
		initialValues: {
      senha_antiga: '',
      nova_senha: '',
    },
		validationSchema: Yup.object().shape({
      senha_antiga: Yup.string().required('Campo obrigatório!').min(6, 'Digite no mínimo 6 caracteres!'),
      nova_senha: Yup.string().required('Campo obrigatório!').min(6, 'Digite no mínimo 6 caracteres!'),
    }),
    onSubmit: async (values) => {
      try {
        await api.post(`lojistas/alterar_senha/${user.id}`, values);
        toast.success('Senha alterada com sucesso!');
        onClose()
      } catch (error) {
        const message = error.response?.data?.message || 'Erro ao alterar senha!';
        toast.error(message);
      }
    }
	});

  function formikProps(fieldName: 'senha_antiga' | 'nova_senha') {
    const error = (formik.touched[fieldName] ? formik.errors[fieldName] : undefined) as string;

    return {
      value: formik.values[fieldName],
      error
    };
  }

  function handleChange(event: any) {
    const value = event?.target.value;
    const field = event?.target.name;

    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  }
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Alteração de senha</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justify="flex-end">
          <Grid item xs={12}>
            <TextField
              name="senha_antiga" 
              label="Senha antiga" 
              type="password"
              fullWidth 
              {...formikProps('senha_antiga')}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              name="nova_senha" 
              label="Nova senha"
              type="password"
              fullWidth 
              {...formikProps('nova_senha')}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <Button variant="contained" onClick={() => formik.handleSubmit()}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePasswordModal;