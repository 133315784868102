import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  height: 100%;
  width: 100%;

  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  height: 250px;
`;