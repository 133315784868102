import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

interface DeleteDialogProps {
  open: boolean;
  onClose(): void;
  onSubmit(): void;
}

// import { Container } from './styles';

const DeleteDialog: React.FC<DeleteDialogProps> = ({ onClose, onSubmit, open }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>ATENÇÃO!</DialogTitle>
      <DialogContent>Deseja realmente excluir esse dado? Essa ação não pode ser desfeita!</DialogContent>
      <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <Button variant="contained" onClick={onSubmit}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;