/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import Crop from "../crop";

// import { Container } from './styles';

interface CropDialogProps {
  open: boolean;
  onClose(): void;
  onSubmit(blob: any): void;
  imageProps: {
    imagePreview: any;
    setImagePreview: any;
    imageBlob: any;
    setImageBlob(base64: any): void;
  };
}

const CropDialog = ({
  open,
  onClose,
  onSubmit,
  imageProps,
}: CropDialogProps) => {
  useEffect(() => {
    if (imageProps.imageBlob && open) {
      imageProps.setImagePreview(imageProps.imageBlob);
    }
  }, [imageProps.imageBlob, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Imagem</DialogTitle>
        <DialogContent>
          <Crop
            imagePreview={imageProps.imagePreview}
            setImagePreview={imageProps.setImagePreview}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            variant="contained"
            onClick={() => onSubmit(imageProps.imagePreview)}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CropDialog;
