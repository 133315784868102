import {
  all,
  takeLatest,
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';

import {
  SignInRequest,
  SIGN_IN_REQUEST,
  SET_TOKEN,
  SetToken,
} from './types';

import { signInSuccess, setLoading, setToken } from './actions';

import api, { interceptor } from '../../../services/api';
import { toast } from 'react-toastify';

function* signIn(action: SignInRequest) {
  const { email, senha } = action.payload;


  try {
    const { data } = yield call(api.post, 'lojista/login', {
      email,
      senha,
    });

    console.log(data)

    yield put(signInSuccess({ token: data.token, user: data.user }));
    yield put(setToken(data.token));
  } catch (error) {
    yield put(setLoading(false));
    if (error.response.status === 401) {
      toast.error('Email ou senha inválidos');
    } else {
      const message =
        error.response?.data?.message ||
        'Erro ao realizar login, tente mais tarde!';
  
      toast.error(message);
    }
  }
}

function* setBearerToken(action: SetToken) {
  const state = yield select((state) => state.auth);

  const token = action.payload || state.token

  if(!token) return

  if (interceptor.id !== null) {
    api.interceptors.request.eject(interceptor.id);
    interceptor.id = null;
  }

  interceptor.id = api.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  console.log(interceptor.id)
}

export default all([
  takeLatest(SIGN_IN_REQUEST, signIn),
  takeEvery(SET_TOKEN, setBearerToken),
]);
