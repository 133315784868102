import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 300px;

  padding: 65px 50px;

  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);

  background-color: #FFF;

  .MuiButton-contained {
    margin-top: 10px;
  }
`;

export const Image = styled.img`
  height: 200px;
`;
