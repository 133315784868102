import React from 'react';

import { TextField as Input, StandardTextFieldProps } from '@material-ui/core';

import { Error } from './styles';

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: string | boolean;
}

const TextField: React.FC<Props> = ({ error, variant = "standard", ...props }) => {
  return (
    <>
      <Input {...props} />
      {error && (
        <Error>{error}</Error>
      )}
    </>
   );
}

export default TextField;