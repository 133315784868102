import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import '../config/reactotron';

import createStore from './createStore';
import persistReducers from './persistReducers';

import reducers from './modules/reducers';
import sagas from './modules/sagas';

const middlewares = [];

const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

middlewares.push(sagaMiddleware);

const store = createStore(persistReducers(reducers), middlewares)

const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };

export type RootState = ReturnType<typeof reducers>;